/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const scrollTo = (id) => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo({top: el.offsetTop - 60, left: 0, behavior: 'smooth'});
  return false
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Your existing code for handling the hash and scrolling
  const { hash } = location;
  if (hash) {
    window.setTimeout(() => scrollTo(hash), 5);
  }

  // The code to push the custom event to the data layer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'gatsby-route-change',
    path: location.pathname,
    referrer: prevLocation ? prevLocation.pathname : null
  });
}


//This is a message from the PWA caching busting
export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}


